
.day-view-chunk {
    cursor: pointer;
    opacity: 0.7;
    background: green;
    line-height: 1.8em;
}

.day-view-chunk:hover {
    box-shadow: 5px 5px 5px grey;
}
