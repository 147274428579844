.wave-wrapper {
  background: white;
  border: solid 1px #aaaaaa;
  overflow: hidden;
}

.wave-canvas-wrapper {
  position: relative;
  overflow: hidden;
}

.wave-canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.wave-axis,
.fft-axis,
.fft-vertical-axis {
  font-size: 0.8em;
}

.unselectable {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.wave-region {
  cursor: pointer;
}

.wave-region:hover {
  box-shadow: 5px 5px 5px grey;
}

.loading-spectogram {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}
